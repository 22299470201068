<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <!-- <img src="../../assets/images/logo.svg"> -->
                </div>
                <h4>Hola! </h4>
                <h6 class="font-weight-light">Inicia session para poder continuar</h6>
                <form class="pt-3">
                  <div class="form-group">
                    <input type="email" v-model="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="correo">
                  </div>
                  <div class="form-group">
                    <input type="password" v-model="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="contraseña">
                  </div>
                  <div class="mt-3">
                    <button type="button" @click="login" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">Ingresar</button>
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" v-model="keepMeSigned" class="form-check-input" checked>
                          Mantener sesion
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      keepMeSigned: '',
    }
  },
  methods: {
      login(){
        this.$axios.post('/api/login', {
          email: this.email,
          password: this.password
        }).then(res => {
          this.$store.commit('setToken', {token: res.data.access_token})
          this.$store.commit('setExpiredIn', {expiredIn: res.data.expires_in})
          if(this.keepMeSigned){
            localStorage.setItem('token', res.data.access_token)
            localStorage.setItem('expiredIn', res.data.expires_in)
            localStorage.setItem('refreshToken', res.data.refresh_token)
            this.$store.commit('setRefreshToken', {refreshToken: res.data.refresh_token})
          }
          this.$store.dispatch('getProfile')
          this.$router.push('/companies-select')
        })
      }
  }
}
</script>
